<div class="background"></div>
<ion-img class="logo" src="../../assets/img/hunter.png"></ion-img>
<ion-spinner class="loading-spinner non-standard-loading-spinner white"></ion-spinner>

<div class="disable-background" *ngIf="showQR()" (click)="hideQR()">
  <div class="pop-up" style="padding-top: 15px; top: 120px; background-color: transparent; left: unset; width: unset;">
    <qrcode [qrdata]="qrCode()"></qrcode>
  </div>
</div>
  
<div (click)="debugTap()" class="loader-menu" *ngIf="!isDesktop() && !blackLoader()">
  <ion-toolbar style="z-index: 0 !important;"></ion-toolbar>
  <ion-spinner class="loading-spinner non-standard-loading-spinner" style="color: black;"></ion-spinner>
</div>

<div (click)="debugTap()" class="loader-menu desktop-app" *ngIf="isDesktop() && !blackLoader()">
  <ion-toolbar style="z-index: 0 !important;"></ion-toolbar>
  <ion-spinner class="loading-spinner non-standard-loading-spinner" style="color: black;"></ion-spinner>
</div>
  
<div class="debug-pop-up" *ngIf="DisplayDebugPopup()">
  <div *ngIf="loadingLog">
    Loading log...
  </div>
  <div *ngIf="!loadingLog">
    <div class="debug-title">You have entered the debug menu.<br><br>
    What would you like to do?</div><br><br>
    <div *ngIf="!debugMode()" (click)="debugModeToggle()">Enter debug mode.</div>
    <div *ngIf="debugMode()" (click)="debugModeToggle()">Exit debug mode.</div>
    <br><br>
    <div (click)="debugDisplayLog()">Display debug log.</div><br><br>
    <div (click)="debugResetData()">Reset local data.</div><br><br>
    <div (click)="debugDashboard()">Go to Dashboard.</div><br><br>
    <div (click)="debugLeaveMenu()">Leave menu.</div>
  </div>
</div>

<div class="confetti-container" *ngIf="showConfetti()">
  <div *ngFor="let c of [].constructor(100); let i = index" [class]="'confetti-' + i"></div>
</div>

<ion-app *ngIf="!isDesktop()">
  <div class="disable-background" *ngIf="isLoading()">
    <div class="pop-up" (click)="debugTap()">
      <ion-spinner class="loading-spinner non-standard-loading-spinner" style="color: black;"></ion-spinner>
    </div>
  </div>
  <div *ngIf="showImportLoading()" class="disable-background" style="z-index: 30011">
    <div class="pop-up" (click)="debugTap()">
      <div class="pop-up-title">
        {{ "IMPORTINGCONTACTS" | translate}}
      </div>
      {{ displayContactimportProgressLength() }} / {{ displayContactImportLength() }}
    </div>
  </div>
  <div *ngIf="showForcedLoadingSub()" class="disable-background" style="z-index: 30010">
    <div class="pop-up" (click)="debugTap()">
      <ion-spinner class="loading-spinner non-standard-loading-spinner" style="color: black;"></ion-spinner>
    </div>
    <div *ngIf="showCancelSub">
      Cancel
    </div>
  </div>

  <ion-menu class="ion-desktop-menu" side="start" menuId="first" contentId="content">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button mode="md" (click)="closeMenu()" style="margin-left: -12px;">
            <ion-icon class="new-style-icon" [src]="'assets/icon/x-close.svg'"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>{{"MENU" | translate}}</ion-title>

      </ion-toolbar>
    </ion-header>
    <ion-content id="content" class="phone-menu">
      <div class="menu-padder"></div>
      <div class="menu-page-item" *ngFor="let page of menuPages">
        <ion-menu-toggle auto-hide="true">
          <ion-item button ion-button-effect lines="none" (click)="openPage(page)" class="menu-items">
            <ion-button expand="block" class="menu-button buttons-list" disable-hover 
              fill="clear" size="default"> 
              <ion-text>{{page.title | translate}}</ion-text>
            </ion-button>
            <ion-icon class="icons-menu" [src]="page.icon" slot="start"></ion-icon>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-item>
        </ion-menu-toggle>
      </div>
      <ion-footer style="position: absolute; bottom: 80px;">
        <ion-menu-toggle (click)="logout()" auto-hide="true">
          <ion-item class="logout-footer" button ion-button-effect lines="none">
            <ion-icon class="new-style-icon sign-out" [src]="'assets/icon/log-out-01.svg'"></ion-icon>

            <ion-button expand="block" class="menu-button" disable-hover menuClose="start"
              fill="clear" color="danger" size="default">
              <ion-text>{{"LOGOUT" | translate}} </ion-text>
            </ion-button>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-item>
        </ion-menu-toggle>
      </ion-footer>
    </ion-content>
  </ion-menu>

  <!-- <ion-router-outlet></ion-router-outlet> -->
  <div *ngIf="isIos()" style="height: 100%; overflow: scroll; padding-top: 100px;">
    <router-outlet class="router-outlet"></router-outlet>
  </div>
  <div *ngIf="!isIos()" style="height: 100%; overflow: scroll; padding-top: 80px;">
    <router-outlet class="router-outlet"></router-outlet>
  </div>
</ion-app>

<ion-app *ngIf="isDesktop()">
  <div class="disable-background" *ngIf="isLoading()">
    <div class="pop-up" (click)="debugTap()">
      <ion-spinner class="loading-spinner non-standard-loading-spinner" style="color: black;"></ion-spinner>
    </div>
  </div>
  <div *ngIf="showImportLoading()" class="disable-background" style="z-index: 30011">
    <div class="pop-up" (click)="debugTap()">
      <div class="pop-up-title">
        {{ "IMPORTINGCONTACTS" | translate}}
      </div>
      {{ displayContactimportProgressLength() }} / {{ displayContactImportLength() }}
    </div>
  </div>
  <div *ngIf="showForcedLoadingSub()" class="disable-background" style="z-index: 30010">
    <div class="pop-up" (click)="debugTap()">
      <ion-spinner *ngIf="!showCancelSub()" class="loading-spinner non-standard-loading-spinner" style="color: black;"></ion-spinner>
      <div class="cancel-sub-load" *ngIf="showCancelSub()" (click)="cancelSubLoad()">
        {{ "CANCEL" | translate }}
      </div>
    </div>
  </div>

  <div class="desktop-menu" side="start" menuId="first" contentId="content" *ngIf="!hideMenu()">
    <ion-header>
      <ion-toolbar>
        <ion-img class="menu-logo" src="../../assets/img/hunter.png" *ngIf="!debugMode()" (click)="dasboardTap()"></ion-img>
      </ion-toolbar>
    </ion-header>
    <ion-content class="left-menu-desktop" id="content">
      <div class="desktop-menu-holder">
        <div class="menu-page-item" *ngFor="let page of menuPages">
          <ion-item button ion-button-effect lines="none" (click)="openPage(page)" class="menu-items">
            <div class="menu-button buttons-list" disable-hover 
              fill="clear" size="default"> 
              <ion-text>{{page.title | translate}}</ion-text>
            </div>
            <ion-icon class="icons-menu" [src]="page.icon" slot="start"></ion-icon>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-item>
        </div>
      </div>
      <ion-footer style="position: absolute;">
        <ion-item (click)="logout()" button ion-button-effect lines="none">
          <!-- <ion-icon color="danger" mode="md" name="log-out-outline" class="logout-button absolute-image"></ion-icon> -->
          <ion-icon class="new-style-icon sign-out" [src]="'assets/icon/log-out-01.svg'"></ion-icon>
          <ion-button expand="block" class="menu-button" disable-hover menuClose="start" 
            fill="clear" color="danger" size="default">
            {{"LOGOUT" | translate}} 
          </ion-button>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-item>
      </ion-footer>
    </ion-content>
  </div>

  <!--<ion-router-outlet *ngIf="!hideMenu()" class="desktop-app"></ion-router-outlet>-->
  <!--<ion-router-outlet *ngIf="hideMenu()"></ion-router-outlet>-->
  <div *ngIf="!hideMenu()" class="desktop-app" style="padding-top: 80px;">
    <router-outlet class="router-outlet"></router-outlet>
  </div>
  <div *ngIf="hideMenu()" style="padding-top: 80px;">
    <router-outlet class="router-outlet"></router-outlet>
  </div>
</ion-app>