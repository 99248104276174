import { Component, OnInit, NgZone } from '@angular/core';
import Contact from 'src/app/models/contact';
import { Platform, ModalController, NavController, AlertController } from '@ionic/angular';
import { ContactService } from 'src/app/services/contact.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'src/app/services/settings.service';
import { ImportService } from 'src/app/services/import.service';
import { GlobalService } from 'src/app/services/global.service';
import { SubscriptionService} from "src/app/services/subscription.service";

@Component({
  selector: 'app-select-contacts',
  templateUrl: './select-contacts.page.html',
  styleUrls: ['./select-contacts.page.scss'],
})
export class SelectContactsPage {
  public maximum: number = undefined;
  public contacts: Contact[];
  public selectedContacts: Contact[] = [];
  public isLoadingContacts: boolean = false;
  public fetchNewContacts: boolean = true;
  public getNativeContacts: boolean = false;
  public getFullContacts: boolean = false;
  public importing: boolean = false;
  public pageSize = 9;
  public stopImport: boolean = false;
  public beginLoading: boolean = false;
  public receivedContacts: boolean = false;
  public ContactsPageContactList = [];
  public ContactsPageFavouriteContactList = [];
  public dismissed = false;
  public finalContacts = [];
  public selectAllAllowed = true;
  public addToEntity = false;

  constructor(
    private modalController: ModalController,
    private navController: NavController,
    public contactService: ContactService,
    private alertController: AlertController,
    private translateService: TranslateService,
    private importService: ImportService,
    private zone: NgZone,
    private subscriptionService: SubscriptionService,
    private platform: Platform, 
    private globalService: GlobalService
  ) {}

  async ngOnInit (){
    if (this.getNativeContacts) {
      this.importing = true;
      this.importService.loadingImport = true;
      GlobalService.log("setting loadingImport to true, displaying loading icon");
    }
    
    if(this.selectedContacts == undefined){
      this.selectedContacts = [];
    } else {
      GlobalService.log("Selected Contacts:");
      GlobalService.log(this.selectedContacts);
    }

    if(this.receivedContacts){
      let counter = 1;
      this.contacts.forEach(contact => {
        contact.id_raw = counter.toString();
        counter++;
      });
    }
    await this.ionViewWillEnterManual();
  }

  public async ionViewWillEnterManual () {
    GlobalService.log("Enter selection page");
    ContactService.PageNumber = 0;
    this.beginLoading = true;

    if (this.getNativeContacts) {
      this.isLoadingContacts = true;
      this.getNativeContacts = false; 
      this.contacts = [];

      GlobalService.log("gathering native contacts");
      this.finalContacts = await this.importService.fillArrayWithNativeContacts(this.contacts);
      GlobalService.log("succeeded at acquiring native contacts");
      ContactService.SetSearchedContacts(this.contacts);
      this.isLoadingContacts = false;
      this.importService.loadingImport = false;
      GlobalService.log("setting loadingImport to false, displaying contacts in console log ONLY");

      console.log("final contacts", this.finalContacts);
      this.contacts = this.finalContacts;
    } else if (this.fetchNewContacts) {
      if(!this.receivedContacts){
        GlobalService.log("Fetching new contacts");
        this.getContacts();
      } else {
        if(!this.maximum) {
          this.importing = true;
        }
        ContactService.SetSearchedContacts(this.contacts);
        this.isLoadingContacts = false;
      }
    } else { 
      this.fetchNewContacts = true; // Ensure fetchNewContacts is on for future contact selects
    }

    //ContactService.DashboardContactList = [];
    this.ContactsPageContactList = ContactService.ContactsPageContactList;
    this.ContactsPageFavouriteContactList = ContactService.ContactsPageFavouriteContactList;
    ContactService.ContactsPageContactList = [];
    ContactService.ContactsPageFavouriteContactList = [];
  }

  public ionViewDidLeaveManual () {
    SettingsService.setSubmitted(false);
  }

  public getContacts () {
    this.isLoadingContacts = true;

    if(this.getFullContacts){
      this.contactService.getAllContacts(true).then(contacts => {
        this.contacts = [...contacts].sort((a, b) =>
        a.first_name?.localeCompare(b.first_name)
        );
  
        this.isLoadingContacts = false;
      }).catch(async err => {
        let buttons = [];
        buttons.push({
          cssClass: "cancelButton",
          text: 'OK',
          handler: () => {
            this.dismiss(false);
          }
        });
  
        this.isLoadingContacts = false;
        this.globalService.ionicAlert(
          buttons,
          "ERROR",
          "LOADINGCONTACTSFAILED",
          "",
          false,
          true
        );
      });
    } else {
      this.contactService.getAllContacts().then(contacts => {
        this.contacts = [...contacts].sort((a, b) =>
        a.first_name?.localeCompare(b.first_name)
        );
  
        this.isLoadingContacts = false;
      }).catch(async err => {
        let buttons = [];
        buttons.push({
          cssClass: "cancelButton",
          text: 'OK',
          handler: () => {
            this.dismiss(false);
          }
        });
  
        this.isLoadingContacts = false;
        this.globalService.ionicAlert(
          buttons,
          "ERROR",
          "LOADINGCONTACTSFAILED",
          "",
          false,
          true
        );
      });
    }
  }

  public searchedContacts(){
    return ContactService.SearchedContacts;
  }

  public dismiss(dismissed) {
    GlobalService.log(this.selectedContacts);
    GlobalService.log("dismissed", dismissed);
    
    ContactService.ContactsPageContactList = this.ContactsPageContactList;
    ContactService.ContactsPageFavouriteContactList = this.ContactsPageFavouriteContactList;
    
    this.modalController.dismiss({
      dismissed: dismissed,
      contacts: this.selectedContacts
    });
  }

  public goBack() {
    ContactService.ContactsPageContactList = this.ContactsPageContactList;
    ContactService.ContactsPageFavouriteContactList = this.ContactsPageFavouriteContactList;
    GlobalService.log("Go back and reset contact lists");
    this.ionViewDidLeaveManual();
    this.dismiss(false);
  }

  public async submit() {
    GlobalService.log(await this.contactService.countLocalList());
    GlobalService.log(this.selectedContacts.length);
    GlobalService.log(SubscriptionService.Subscribed);
    if(this.importing){
      if(await this.subscriptionService.checkSubscription(await this.contactService.countLocalList() + this.selectedContacts.length, "CONTACTS")){
        this.dismiss(true);
      } else {
        this.stopImport = true;
      }
    } else {
      this.dismiss(true);
    }
  }

  private compareContacts (contact: Contact) {
    if (contact.id_raw) return x => (x.id_raw === contact.id_raw);
    
    return x => (contact.id_contact != undefined && x.id_contact === contact.id_contact);
  }

  public contactIsSelected (contact: Contact): boolean {
    return this.selectedContacts.some(this.compareContacts(contact));
  }

  public async selectContact (contact: Contact, selectingAll: boolean = false) {
    if(GlobalService.DebugMode) GlobalService.log("selected contact");
    if(GlobalService.DebugMode) GlobalService.log(contact);
    if(this.contactIsSelected(contact)){
      this.selectedContacts.splice(this.selectedContacts.findIndex(this.compareContacts(contact)),1);
    } else {
      if(this.importing && !this.stopImport){
        if(await this.subscriptionService.checkSubscription(await this.contactService.countLocalList() + this.selectedContacts.length, "CONTACTS", false)){
          if(this.maximum == 1){
            this.selectedContacts = [];
            this.selectedContacts.push(contact);
          } else {
            this.selectedContacts.push(contact);
          }
        }
        else {
          GlobalService.log(this.maximum);
          this.stopImport = true;
          if(!selectingAll){
            this.SubscribeDialogue("CONTACTS");
          }
        }
      } else {
        if(this.maximum){
          if(this.maximum == 1){
            this.selectedContacts = [];
            this.selectedContacts.push(contact);
          }
          else if(this.selectedContacts.length >= this.maximum){
            this.MaximumSelected()
          } else {
            this.selectedContacts.push(contact);
          }
        } else {
          this.selectedContacts.push(contact);
        }
      }
    }
  }

  public async selectAll () {
    if(!this.importing){
      this.contacts.forEach(contact => {
        this.selectContact(contact, true)
      });
    } else {
      GlobalService.log(await this.contactService.countLocalList());
      if(await this.subscriptionService.checkSubscription(await this.contactService.countLocalList() + this.contacts.length, "CONTACTS")){
        this.contacts.forEach(contact => {
          this.selectContact(contact, true)
        });
      } else {
        this.stopImport = true;
        this.contacts.forEach(contact => {
          if(this.contactIsSelected(contact)){
            GlobalService.log("remove selected contact");
            this.selectContact(contact, true)
          }
        });
      }
    }
  }
  
  public async SubscribeDialogue (type) {
    let buttons = [];
    buttons.push({
      text: this.translateService.instant("Subscribe"),
      cssClass: "optionButton",
      handler: () => {
        this.subscriptionService.orderProduct();
      }
    });
    
    buttons.push({
      text: this.translateService.instant("CANCEL"),
      cssClass: "cancelButton",
      handler: () => {
        this.stopImport = false;
      }
    });

    this.isLoadingContacts = false;
    this.globalService.ionicAlert(
      buttons,
      this.translateService.instant("YOUMAYNOTCREATEMORETHAN") + SubscriptionService.MaxValue + " " +
      this.translateService.instant(type) + this.translateService.instant("INTHEFREEVERSION")
    );
  }

  public next(){
    ContactService.PageNumber = ContactService.PageNumber + this.pageSize;
  }

  public previous(){
    ContactService.PageNumber = ContactService.PageNumber - this.pageSize
  }

  public pageNumber(){
    return ContactService.PageNumber;
  }

  public isLoadingImport(){
    return this.importService.loadingImport;
  }

  public searchContacts(eventData){
    if(eventData.detail.value){
      let searchValue = eventData.detail.value
      GlobalService.log(searchValue);
      GlobalService.log(ContactService.SearchedContacts);
      ContactService.SetSearchedContacts(ContactService.SearchedContacts);
  
      let newContacts = [];
      ContactService.SearchedContacts.forEach(contact => {
        try{
          if((contact.first_name && contact.first_name.toLowerCase().includes(searchValue.toLowerCase()))
            || (contact.last_name && contact.last_name.toLowerCase().includes(searchValue.toLowerCase()))
            || (contact.company && contact.company.toLowerCase().includes(searchValue.toLowerCase()))
            || (contact.function && contact.function.toLowerCase().includes(searchValue.toLowerCase()))){
              newContacts.push(contact);
          }
        } catch (e) {
          GlobalService.log("Error in adding contact", contact);
        }
      });
      this.contacts = newContacts;
  
      ContactService.PageNumber = 0;
      GlobalService.log(this.contacts);
    } else {
      this.contacts = ContactService.SearchedContacts;
    }
  }
  
  public async MaximumSelected () {
    let buttons = [];
    buttons.push({
      text: this.translateService.instant("OKAY"),
      cssClass: "cancelButton"
    });
    this.globalService.ionicAlert(
      buttons,
      this.translateService.instant("You may not convert more than 5 contacts to a QR-code at the same time")
    );
  }
  
  isIos(){
    return this.globalService.isIos();
  }

  debugTap(){
    GlobalService.debugTap();
  }
}
