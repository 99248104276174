import { Injectable } from "@angular/core";
import { CrudService } from "src/app/services/crud.service";
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Storage } from "@ionic/storage-angular";
import User from "../models/user";
import { AlertController, NavController, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { JWT } from '../models/jwt';
import { LoginAttempt } from '../models/login-attempt';
import { jwtDecode } from "jwt-decode";
import { GlobalService } from "./global.service";
import { lastValueFrom } from 'rxjs';
import { StorageService } from "./storage.service";
import * as Sentry from "@sentry/capacitor";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private static user: User;
  private static accessToken: string;
  private static loggedOut: boolean = false;
  private static temporaryRememberMe: {username, password} = {username: undefined, password: undefined};
  private isShowingAlert: boolean = false;
  public backupToken = "";

  public static get LoggedOut() {
    return this.loggedOut;
  }

  public static set LoggedOut(value) {
    this.loggedOut = value;
  }

  public static get AccessToken() {
    return this.accessToken;
  }

  public static set AccessToken(token) {
    this.accessToken = token;
  }

  public static get User() {
    return this.user;
  }

  public static set User(value) {
    this.user = value;
  }

  public static get TemporaryRememberMe() {
    return this.temporaryRememberMe;
  }

  public static set TemporaryRememberMe(value) {
    this.temporaryRememberMe = value;
  }

  constructor(
    public httpClient: HttpClient,
    public navController: NavController,
    public toastController: ToastController,
    public translateService: TranslateService,
    public alertController: AlertController,
    public globalService: GlobalService,
    public storageService: StorageService,
    public crudService: CrudService
  ) {
  }

  async initializeService() {

    let raja = this.storageService.getStorage("raja");
    CrudService.SetAuthToken(await raja);
  }

  public async getUserObj () : Promise<User> {
    if(GlobalService.DebugMode) console.log("Get user object triggered");

    if (AuthService.User) {
      return AuthService.User;
    }

    let user_obj = await this.storageService.getStorage("user_obj")
    let storedUser = user_obj as User;
    AuthService.user = storedUser;

    if(GlobalService.DebugMode) console.log("couldn't find user object, attempting login with saved credentials");
    await this.login(undefined, undefined, true, true)

    if(user_obj && user_obj.user_email){
      console.log("user info", user_obj);
      GlobalService.log("!divide!email", user_obj.user_email + "!divide!")
      Sentry.setUser(user_obj.user_email)
    }
    return undefined;
  }

  public async logout(): Promise<any> {
    AuthService.LoggedOut = true;
    GlobalService.HideMenu = true;
    await this.emptyUserContacts();
    return this.storageService.setStorage("raja", "").then(() => {
      CrudService.SetAuthToken(undefined);
      AuthService.user = undefined;
    });
  }

  public async emptyUserContacts(){
    await this.storageService.setStorage(`localContacts_${(await this.getUserObj()).id_user}`, undefined, false);
    await this.storageService.setStorage(`contactSyncDate_${(await this.getUserObj()).id_user}`, undefined);
  }

  public getAccessToken(){
    return this.crudService.getToken();
  }

  public checkLogin(): Promise<boolean> {
    return this.crudService.post("/login/validate", { token: AuthService.AccessToken })
      .then((res) => {
        console.log(res);
        if (res) {
          GlobalService.HideMenu = false;
          return true;
        } else {
          return false;
        }
      })
      .catch((err) => {
        return false;
      });
  }
  
  private async performLogin (username, password, remember: boolean) : Promise<any> {
    let options = {
      headers: new HttpHeaders().append("Content-Type", "application/x-www-form-urlencoded")
    };

    let body = new HttpParams()
    .append("username", username)
    .append("password", password)
    .append("client_id", "actablue_login")
    .append("client_secret", "2b0e3c77-57e0-42c5-889b-4a14d023abfd")
    .append("grant_type", "password");

    if(GlobalService.DebugMode) console.log("login body", body);

    let res = await lastValueFrom(this.httpClient.post<HttpResponse<any>>("https://keycloak.actablue.com/auth/realms/actablue/protocol/openid-connect/token", body.toString(), options)).catch((e) => {
      if(e.error.error == "invalid_grant"){
        if(e.error.error_description == "Account is not fully set up"){
          this.loginError("PLEASEVERIFYEMAIL")
        } else {
          console.log("Error 156, wrong login")
          if(username || password) this.loginError("WRONGLOGIN")
        }
      } else {
        if(!navigator.onLine) { this.loginError("NOINTERNET") } 
        else { 
          console.log("Erorr 162")
          if(username || password) this.loginError("WRONGLOGINKEYCLOAK") 
        }
      }

      return e;
    });
    
    // Save credentials for future use
    if(GlobalService.DebugMode) console.log("Resolution", res);

    this.storageService.setStorage("credentials", {
      username: username,
      password: password
    }, false);

    this.loginSuccess();
    return res;
  }

  public async loginError(error){
    if (!this.isShowingAlert) {
      let buttons = [];
      buttons.push();

      if(!navigator.onLine){
        buttons = [
          {
            cssClass: "subButton",
            text: this.translateService.instant("CONTINUE"),
            handler: () => {
              this.navController.navigateRoot('login');
            }
          }
        ];
      } else {
        buttons = [
          {
            cssClass: "subButton",
            text: this.translateService.instant("LOGIN"),
            handler: () => {
              this.navController.navigateRoot('login');
              this.isShowingAlert = false;
            }
          },
          {
            cssClass: "subButton",
            text: this.translateService.instant("SIGNUP"),
            handler: () => {
              GlobalService.ForceSignUpPage = true;
              this.navController.navigateRoot('login');
              this.isShowingAlert = false;
            }
          }
        ];
      }
      
      this.globalService.ionicAlert(
        buttons,
        "ERROR",
        error
      );

      this.isShowingAlert = true;
    }
  }

  public async login(username: string, password: string, remember: boolean, auto: boolean = false) : Promise<LoginAttempt> {
    //AuthService.AccessToken = undefined;
    //CrudService.SetAuthToken(undefined);
    //storage.set("raja", undefined);
    console.log("login triggered");

    if(!username && !password){
      if(GlobalService.DebugMode) console.log("No username detected, trying temporary");
      if(AuthService.TemporaryRememberMe.username && AuthService.TemporaryRememberMe.password){
        if(GlobalService.DebugMode) console.log("trying temporary", AuthService.TemporaryRememberMe.username);
        username = AuthService.TemporaryRememberMe.username;
        password = AuthService.TemporaryRememberMe.password;
        return await this.loginPartTwo(username, password, remember, auto);
      } else {
        if(GlobalService.DebugMode) console.log("No temporary username detected, trying storage");

        let credentials = await this.storageService.getStorage("credentials");
        
        if(GlobalService.DebugMode) console.log("has credentials?", credentials);
        if(credentials){
          if(GlobalService.DebugMode) console.log("trying saved credentials", credentials.username);
          username = credentials.username;
          password = credentials.password;

          return await this.loginPartTwo(username, password, remember, auto);
        } else {
          if(GlobalService.DebugMode) console.log("Storage login failed, cause error");
          return await this.loginPartTwo(username, password, remember, auto);
        }
      }
    } else {
      if(GlobalService.DebugMode) console.log(username);
      return await this.loginPartTwo(username, password, remember, auto);
    }
  }

  public async loginPartTwo(username: string, password: string, remember: boolean = true, auto: boolean = false){
    let res = await this.performLogin(username, password, remember);
    if(GlobalService.DebugMode) console.log("part one succeeded", res);

    if(!navigator.onLine) {
      if(GlobalService.DebugMode) console.log("not connected to the internet");
      if(GlobalService.DebugMode) console.log(res);

      return {
        jwt: undefined,
        accessToken: undefined,
        success: false,
        message: "NOINTERNET"
      };
    } else if (res.status && res.status == 401) {
      if(GlobalService.DebugMode) console.log("login failed");
      if(GlobalService.DebugMode) console.log(res);

      return {
        jwt: undefined,
        accessToken: undefined,
        success: false,
        message: "WRONGLOGIN"
      };
    } else if(res.status && res.status == 500){
      if(GlobalService.DebugMode) console.log("connection failed");
      if(GlobalService.DebugMode) console.log(res);

      return {
        jwt: undefined,
        accessToken: undefined,
        success: false,
        message: "WRONGLOGINKEYCLOAK"
      };
    }
    else{
      AuthService.TemporaryRememberMe = {username: username, password: password};
      console.log(remember);
      if (!remember && !auto) {
        console.log("clear out saved credentials");
        this.storageService.setStorage("credentials", null);
        this.storageService.setStorage("remember", false);
      }
      this.backupToken = res.access_token;
      AuthService.AccessToken = res.access_token;
      CrudService.SetAuthToken(res.access_token);
      this.storageService.setStorage("raja", res.access_token, false);

      let jwt: JWT = jwtDecode(res.access_token);

      AuthService.user = {id_user: jwt.sub, user_email: username};
      this.storageService.setStorage("user_obj", AuthService.user, false);

      if (remember) {
        if(GlobalService.DebugMode) console.log("remember is true");
        this.storageService.setStorage("credentials", {username: username, password: password}, false);
        this.storageService.setStorage("remember", true);
      }
      if(GlobalService.DebugMode) console.log("No errors, attempting to login");

      return {
        jwt: jwt,
        accessToken: AuthService.AccessToken,
        success: true,
        message: "Login successful"
      };
    }
  }

  public async register(username: string, password: string, email: string){
    if(GlobalService.DebugMode) GlobalService.log("attempted to register", username);
    let body = {username: username, password: password, email: email};
    let res = await this.crudService.post(`${environment.registration}/registration/user/HunterCRM`, body, false, false, false);
    if(GlobalService.DebugMode) console.log(res);

    return res;
  }

  public loginSuccess(){
    AuthService.LoggedOut = false;
    GlobalService.HideMenu = false;
    GlobalService.BlackLoader = false;
  }

  public deleteAccount(){
    console.log("Attempting to delete account");
    this.deleteUserRequest();   
  }

  public async deleteUserRequest(){
    let deleteAccount = await this.crudService.read(`${environment.registration}/registration/user/` + AuthService.user.id_user + `/delete`, false, false);
    GlobalService.log("Delete account attempt", deleteAccount);

    if(deleteAccount && deleteAccount.status && deleteAccount.status != 200){
      let buttons = [];
      buttons.push({
        text: this.translateService.instant("CONTINUE"),
        cssClass: "cancelButton",
      });
      
      this.globalService.ionicAlert(
        buttons,
        "ACCOUNTDELETEDFAILED",
      );
    } else {
      this.logout().then(async () => {
        let buttons = [];
        buttons.push();
        buttons.push({
          text: this.translateService.instant("CONTINUE"),
          cssClass: "cancelButton"
        });
        
        this.globalService.ionicAlert(
          buttons,
          "ACCOUNTDELETED"
        );

        this.navController.navigateRoot('login');
      });
    }
  }

  public async forgotPW(username: string){
    let body = {username: username};

    let res = await this.crudService.post(`${environment.registration}/registration/user/HunterCRM/forgot`, body, null, false, false);

    return res;
  }
}
