import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Router, RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy, AlertController, NavController } from "@ionic/angular";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { IonicStorageModule } from '@ionic/storage-angular';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CropperPage } from './pages/cropper/cropper.page';
import { CropperPageModule } from './pages/cropper/cropper.module';
import { SelectContactsPageModule } from './pages/select-contacts/select-contacts.module';
import { SelectContactsPage } from './pages/select-contacts/select-contacts.page';
import { SelectActionsPageModule } from './pages/select-actions/select-actions.module';
import { SelectActionsPage } from './pages/select-actions/select-actions.page';
import { SelectTouchpointsPageModule } from './pages/select-touchpoints/select-touchpoints.module';
import { SelectTouchpointsPage } from './pages/select-touchpoints/select-touchpoints.page';
import { ActionItemComponentModule } from './components/action-item/action-item.module';
import { TouchpointHistoryItemComponentModule } from './components/touchpoint-history-item/touchpoint-history-item.module';
import { Platform } from '@ionic/angular';
import { SearchPageModule } from './pages/search/search.module';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
// import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { TermsandAgreementsComponentModule } from 'src/app/components/terms-and-agreements/terms-and-agreements.module';
import { CookiesComponentModule } from 'src/app/components/cookies/cookies.module';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { QRCodeModule } from 'angularx-qrcode';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/");
}

import * as Sentry from "@sentry/capacitor";
import * as SentryAngular from "@sentry/angular";
import { environment } from "src/environments/environment";

Sentry.init(
  {
    dsn: "https://7ea44b629646829902598273d44be0c9@sentry.actablue.com/7",
    ignoreErrors: ["cordova_not_available"],  
    // Set your release version, such as "getsentry@1.0.0"
    release: "huntercrm-online@"+ AppVersion,
    // Set your dist version, such as "1"
    dist: "<dist>",
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      // Do not mask during replay, password fields require the sentry-mask class
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", environment.endpoint],
    environment: environment.endpoint,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  },
  // Forward the init method from @sentry/angular
  SentryAngular.init
);

@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    IonicModule.forRoot({mode: 'md', scrollAssist: false }),
    IonicStorageModule.forRoot({
      name: 'hunterdb'
    }),
    AppRoutingModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    ActionItemComponentModule,
    TouchpointHistoryItemComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CropperPageModule,
    SelectContactsPageModule,
    SelectActionsPageModule,
    SelectTouchpointsPageModule,
    TermsandAgreementsComponentModule,
    CookiesComponentModule,
    SearchPageModule,
    QRCodeModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Calendar,
    AppVersion,
    Camera,
    File,
    ScreenOrientation,
    AlertController,
    InAppBrowser,
    // InAppPurchase2,
    NavController,
    FileOpener,
    Badge,
    Keyboard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },{
      provide: ErrorHandler,
      // Attach the Sentry ErrorHandler
      useValue: SentryAngular.createErrorHandler(),
    },
    {
      provide: SentryAngular.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [SentryAngular.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
